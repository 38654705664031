<section class="pt-4 pb-0">
	<div class="container">
		<article>
			<h2>Contact</h2>
			<b><h3> DLM Logistics Inc</h3></b>


			<p>720 Plainfield Rd Ste 105A, Willowbrook, IL, 60527, USA</p>
      <p>Main number: <a href="tel:(872)255-0007">(872)255-0007</a></p>

      <p>Ext 1 - Dispatch - <a href="mailto:dispatch@dlmlogisticsinc.com">dispatch@dlmlogisticsinc.com</a></p>
      <p>Ext 2 - Safety - <a href="mailto:safety@dlmlogisticsinc.com">safety@dlmlogisticsinc.com</a></p>
      <p>Ext 3 - Accounting -  <a href="mailto:accounting@dlmlogisticsinc.com">accounting@dlmlogisticsinc.com</a></p>
      <p>Ext 0 - Info -  <a href="mailto:info@dlmlogisticsinc.com">info@dlmlogisticsinc.com</a></p>

		</article>
	</div>
</section>

